import { Directive } from '@angular/core';

@Directive({
    selector: '[appAos]',
    host: {
        'data-aos': 'fade-up',
        'data-aos-once': 'true',
        'data-aos-duration': '1000'
    },
    standalone: true
})
export class AosDirective {}
