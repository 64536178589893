import { Pipe, PipeTransform } from '@angular/core';
import { HTMLRichTextFunctionSerializer, HTMLRichTextMapSerializer, RichTextField, asHTML } from '@prismicio/client';
@Pipe({
    name: 'renderHtml',
    standalone: true
})
export class RenderHtmlPipe implements PipeTransform {
    transform(value: RichTextField, serializer?: HTMLRichTextMapSerializer | HTMLRichTextFunctionSerializer): unknown {
        return asHTML(value, undefined, serializer);
    }
}
