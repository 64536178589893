import { isPlatformBrowser } from '@angular/common';
import { inject, Injectable, PLATFORM_ID } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ApplicationModalComponent } from './application-modal.component';

@Injectable({
    providedIn: 'root'
})
export class ApplicationModalService {
    private modalRef?: BsModalRef;
    etracker?: Etracker;
    private platformId = inject<Object>(PLATFORM_ID);

    constructor(private modalService: BsModalService) {
        if (isPlatformBrowser(this.platformId)) {
            this.etracker = (window as { [key: string]: any })['_etracker'] as Etracker;
        }
    }

    openModal() {
        this.etracker?.sendEvent(new et_ClickEvent('Bewerbungsformular geöffnet'));
        this.modalRef = this.modalService.show(ApplicationModalComponent, {
            ignoreBackdropClick: true
        });
    }
}
