import { Component, Input, OnInit } from '@angular/core';
import { BooleanField, ImageField, KeyTextField, RichTextField, Slice, TitleField } from '@prismicio/client';
import { HTMLMapSerializer } from '@prismicio/helpers';
import { AosDirective } from '../../aos/aos.directive';
import { RenderHtmlPipe } from '../render-html/render-html.pipe';

@Component({
    selector: 'app-headline-slice',
    templateUrl: './headline-slice.component.html',
    styleUrls: ['./headline-slice.component.scss'],
    standalone: true,
    imports: [AosDirective, RenderHtmlPipe]
})
export class HeadlineSliceComponent implements OnInit {
    @Input() body!: HeadlineSlice;

    constructor() {}

    ngOnInit(): void {}

    htmlSerializer: HTMLMapSerializer = {
        heading2: ({ children }) => `<h2 class="text-size-xl ${this.body.primary.pink_background ? 'color-white' : ''}">${children}</h2>`
    };
}

export type HeadlineSlice = Slice<
    'uberschrift',
    {
        heading: KeyTextField;
        title: TitleField;
        description: RichTextField;
        image: ImageField;
        pink_background: BooleanField;
        padding_bottom: BooleanField;
    },
    never
>;
