import { isPlatformBrowser } from '@angular/common';
import { Component, inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { BooleanField, KeyTextField, LinkField, RichTextField, Slice } from '@prismicio/client';
import { HTMLMapSerializer } from '@prismicio/helpers';
import { LottieComponent } from 'ngx-lottie';
import { serializeHyperlink } from '../../../utils/prismic';
import { LinkComponent } from '../../link/link.component';
import { RenderHtmlPipe } from '../render-html/render-html.pipe';
import { FilterPinnedItemsPipe } from './filter-pinned-items.pipe';
import { FilterPipe } from './filter.pipe';

@Component({
    selector: 'app-faq-slice',
    templateUrl: './faq-slice.component.html',
    styleUrls: ['./faq-slice.component.scss'],
    standalone: true,
    imports: [FormsModule, LottieComponent, LinkComponent, RenderHtmlPipe, TranslateModule, FilterPipe, FilterPinnedItemsPipe]
})
export class FaqSliceComponent implements OnInit {
    @Input() body!: FaqSlice;
    private platformId = inject<Object>(PLATFORM_ID);
    isBrowser = isPlatformBrowser(this.platformId);

    searchTerm?: string;

    constructor() {}

    ngOnInit(): void {}

    htmlSerializer: HTMLMapSerializer = {
        hyperlink: ({ children, node }) => serializeHyperlink(children, node)
    };
}

export type FaqSlice = Slice<
    'banner',
    {
        show_search_field: BooleanField;
        button_title: KeyTextField;
        button_link: LinkField;
        margin_top: BooleanField;
    },
    FaqItem
>;

export type FaqItem = {
    question: KeyTextField;
    answer: RichTextField;
    pinned: BooleanField;
};
