import { Pipe, PipeTransform } from '@angular/core';
import { FaqItem } from './faq-slice.component';

@Pipe({
    name: 'filterPinnedItems',
    standalone: true
})
export class FilterPinnedItemsPipe implements PipeTransform {
    transform(items: FaqItem[], pinned: boolean): FaqItem[] {
        if (!items) {
            return [];
        }

        return items.filter((item) => item.pinned === pinned);
    }
}
