import { Component, Input, OnInit } from '@angular/core';
import { RichTextField, Slice, TitleField } from '@prismicio/client';
import { HTMLMapSerializer } from '@prismicio/helpers';
import { ApplicationFormComponent } from '../../application-form/application-form.component';
import { RenderHtmlPipe } from '../render-html/render-html.pipe';

@Component({
    selector: 'app-application-form-slice',
    templateUrl: './application-form-slice.component.html',
    styleUrls: ['./application-form-slice.component.scss'],
    standalone: true,
    imports: [ApplicationFormComponent, RenderHtmlPipe]
})
export class ApplicationFormSliceComponent implements OnInit {
    @Input() body!: ApplicationFormSlice;

    imageMode?: boolean;

    constructor() {}

    ngOnInit(): void {}

    htmlSerializer: HTMLMapSerializer = {
        heading2: ({ children }) => `<h2 class="color-white">` + children + '</h2>'
    };
}

export type ApplicationFormSlice = Slice<
    'bewerbungsformular',
    {
        title: TitleField;
        description: RichTextField;
    },
    never
>;
