import { Component, Input, OnInit } from '@angular/core';
import { BooleanField, ImageField, KeyTextField, RichTextField, Slice, TitleField } from '@prismicio/client';
import { HTMLMapSerializer } from '@prismicio/helpers';
import { serializeHyperlink } from '../../../utils/prismic';
import { AosDirective } from '../../aos/aos.directive';
import { RenderHtmlPipe } from '../render-html/render-html.pipe';

@Component({
    selector: 'app-image-and-text-slice',
    templateUrl: './image-and-text-slice.component.html',
    styleUrls: ['./image-and-text-slice.component.scss'],
    standalone: true,
    imports: [AosDirective, RenderHtmlPipe]
})
export class ImageAndTextSliceComponent implements OnInit {
    @Input() body!: ImageAndTextSlice;

    constructor() {}

    ngOnInit(): void {}

    htmlSerializer: HTMLMapSerializer = {
        list: ({ children }) => '<ul class="list-unstyled">' + children + '</ul>',
        listItem: ({ children }) => '<li class="mt-2 list-item-point">' + children + '</li>',
        hyperlink: ({ children, node }) => serializeHyperlink(children, node)
    };
}

export type ImageAndTextSlice = Slice<
    'bild_text',
    {
        heading: KeyTextField;
        title: TitleField;
        description: RichTextField;
        image: ImageField;
        image_right: BooleanField;
        mask: BooleanField;
    },
    never
>;
