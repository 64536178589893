import { NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault, NgTemplateOutlet } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LinkField, SelectField } from '@prismicio/client';
import { ApplicationModalService } from '../application-modal/application-modal.service';
import { RatingFormModalService } from '../rating-form-modal/rating-form-modal.service';

@Component({
    selector: 'app-link',
    templateUrl: './link.component.html',
    styleUrls: ['./link.component.scss'],
    standalone: true,
    host: { ngSkipHydration: 'true' },
    imports: [NgSwitch, NgSwitchCase, NgTemplateOutlet, NgSwitchDefault, NgIf, RouterLink, RouterLinkActive]
})
export class LinkComponent implements OnInit {
    @Input() link?: LinkField;
    @Input() action?: SelectField;
    @Input() anker?: string;
    @Input() routerLinkActive: string = '';
    @Input() cssClass?: string;

    lang = '';

    constructor(
        private translateService: TranslateService,
        public applicationModalService: ApplicationModalService,
        public ratingFormModalService: RatingFormModalService
    ) {}

    ngOnInit(): void {
        this.lang = this.translateService.currentLang === 'en' ? '/en' : '';
        this.translateService.onLangChange.subscribe((event) => {
            this.lang = event.lang === 'en' ? '/en' : '';
        });
    }
}
