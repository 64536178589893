import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ApplicationFormComponent } from '../application-form/application-form.component';

@Component({
    selector: 'app-application-modal',
    templateUrl: './application-modal.component.html',
    styleUrls: ['./application-modal.component.scss'],
    standalone: true,
    imports: [ApplicationFormComponent]
})
export class ApplicationModalComponent implements OnInit {
    constructor(private bsModalRef: BsModalRef) {}

    ngOnInit(): void {}

    close() {
        this.bsModalRef.hide();
    }
}
