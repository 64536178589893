import { isPlatformBrowser } from '@angular/common';
import { Component, inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ImageField } from '@prismicio/client';
import { BsModalService } from 'ngx-bootstrap/modal';
import { LottieComponent } from 'ngx-lottie';
import { VideoModalComponent } from '../../../video-modal/video-modal.component';

@Component({
    selector: 'app-application-form-success',
    templateUrl: './application-form-success.component.html',
    styleUrls: ['./application-form-success.component.scss'],
    standalone: true,
    imports: [LottieComponent, TranslateModule]
})
export class ApplicationFormSuccessComponent implements OnInit {
    etracker?: Etracker;

    @Input() assessmentSessionUrl?: string;
    private platformId = inject<Object>(PLATFORM_ID);
    isBrowser = isPlatformBrowser(this.platformId);

    constructor(private modalService: BsModalService) {
        if (this.isBrowser) {
            this.etracker = (window as { [key: string]: any })['_etracker'] as Etracker;
        }
    }

    ngOnInit(): void {}

    openVideoModal() {
        const thumbnail: ImageField | any = {
            url: '/assets/images/christopher-erklaert-assessment-thumbnail.jpg',
            alt: 'Christopher Pilz erklärt Assessment',
            dimensions: {
                width: 0,
                height: 0
            },
            copyright: null
        };

        this.modalService.show(VideoModalComponent, {
            initialState: { video: 'christopher-erklaert-assessment.mp4', thumbnail },
            class: 'modal-lg'
        });
    }

    trackEventStartAssessment() {
        this.etracker?.sendEvent(new et_ClickEvent('Assessment gestartet'));
    }
}
