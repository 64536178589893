export function serializeHyperlink(children: string, node: any) {
    let link;
    const { link_type, url, lang } = node.data;

    switch (link_type) {
        case 'Document': {
            let { type, uid } = node.data;
            uid = uid.replaceAll('startseite', '');

            switch (type) {
                case 'standard-page':
                    link = `${lang === 'en-us' ? '/en/' + uid : '/' + uid}`;
                    break;
                case 'job':
                    link = `/jobs/${uid}`;
                    break;
                case 'partner-company':
                    link = `${lang === 'en-us' ? '/en/partners/' + uid : '/partner/' + uid}`;
                    break;
                case 'education-partner':
                    link = `${lang === 'en-us' ? '/en/education-partners/' + uid : '/bildungspartner/' + uid}`;
                    break;
                case 'article':
                    link = `/artikel/${uid}`;
                    break;
            }

            break;
        }
        default:
            link = url;
    }

    return `<a href="${link}" class="text-decoration-underline color-pink"><span>${children}</span></a>`;
}
