<div class="container">
    <div class="row py-5 py-lg-6">
        <div class="col-lg-6 d-flex" [class.order-lg-1]="body.primary.image_right" appAos>
            <img
                [attr.data-src]="body.primary.image.url"
                [alt]="body.primary.image.alt"
                class="w-75 lazyload m-auto"
                [class.image-mask]="body.primary.mask"
                [class]="body.primary.mask ? (body.primary.image_right ? 'mask-04' : 'mask-05') : ''"
            />
        </div>
        <div
            class="col-lg-6 mt-4 mt-lg-0 d-flex align-items-center"
            [class.pe-lg-6]="body.primary.image_right"
            [class.ps-lg-6]="!body.primary.image_right"
            [class.order-lg-0]="body.primary.image_right"
            appAos
        >
            <div>
                <p class="color-pink text-size-xl">{{ body.primary.heading }}</p>
                <div [innerHTML]="body.primary.title | renderHtml"></div>
                <div [innerHTML]="body.primary.description | renderHtml : htmlSerializer"></div>
            </div>
        </div>
    </div>
</div>
