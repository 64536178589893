<div class="py-5 py-lg-6 wrapper">
    <div class="background-pink py-5 py-lg-6">
        <div class="container">
            <div class="row">
                <div class="col-lg-6">
                    <div class="pe-lg-5">
                        <div [innerHTML]="body?.primary.title | renderHtml : htmlSerializer"></div>
                        <div [innerHTML]="body?.primary.description | renderHtml"></div>
                    </div>
                    <div class="d-flex justify-content-end pe-4">
                        <img src="./assets/images/dots-plus-white.svg" alt="" class="mt-3 icon-big" />
                    </div>
                </div>
                <div class="col-lg-6 mt-5 mt-lg-0 position-relative">
                    <app-application-form></app-application-form>
                </div>
            </div>
        </div>
    </div>
</div>
