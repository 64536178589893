<div class="shadow-lg background-white">
    @if (status === Status.Pending) {
    <app-progress-bar></app-progress-bar>
    }
    <div class="px-4 px-lg-5 py-5">
        @if (status !== Status.Success) {
        <div class="row">
            <div class="col-12 d-flex justify-content-end">
                <button class="button-unstyled" (click)="closeModal()">
                    <img src="./assets/images/close.svg" alt="Close Icon" class="icon-medium" />
                </button>
            </div>
            <div class="col-12">
                <h3>{{ 'GLOBAL.RATING_FORM.DEFAULT.TITLE' | translate }}</h3>
                <p>{{ 'GLOBAL.RATING_FORM.DEFAULT.DESCRIPTION' | translate }}</p>
            </div>
            <div class="col-12">
                <div class="row">
                    <div class="col-12 mt-4">
                        <rating [(ngModel)]="stars" [max]="5"></rating>
                        <span class="ms-3">{{ stars ? stars + '/5' : '(bitte wählen)' }}</span>
                    </div>
                </div>
                <form [formGroup]="formGroup">
                    <div class="row">
                        <div class="col-12 mt-4">
                            <input
                                type="text"
                                formControlName="name"
                                [placeholder]="'GLOBAL.RATING_FORM.DEFAULT.NAME_PLACEHOLDER' | translate"
                                class="w-100"
                            />
                            @if (formGroup?.controls.name?.touched && formGroup?.controls.name?.hasError('required')) {
                            <span class="text-size-xs pt-2">
                                {{ 'GLOBAL.RATING_FORM.DEFAULT.NAME_REQUIRED_MESSAGE' | translate }}
                            </span>
                            }
                        </div>
                        <div class="col-12 mt-4">
                            <textarea
                                formControlName="rating"
                                rows="5"
                                [placeholder]="'GLOBAL.RATING_FORM.DEFAULT.RATING_PLACEHOLDER' | translate"
                            >
                            </textarea>
                            @if (formGroup?.controls.rating?.touched && formGroup?.controls.rating?.hasError('required')) {
                            <span class="text-size-xs pt-2">
                                {{ 'GLOBAL.RATING_FORM.DEFAULT.RATING_REQUIRED_MESSAGE' | translate }}
                            </span>
                            }
                        </div>
                        <div class="col-12 mt-5">
                            <p [innerHTML]="'GLOBAL.RATING_FORM.DEFAULT.PRIVACY_NOTE' | translate" class="text-size-xs"></p>
                        </div>
                        <div class="col-12 mt-4 d-flex justify-content-end'">
                            <button
                                [disabled]="formGroup.invalid || status === Status.Pending || !stars"
                                (click)="submit()"
                                class="btn btn-black"
                            >
                                {{ 'GLOBAL.RATING_FORM.DEFAULT.SUBMIT' | translate }}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
            <div class="col-12 mt-3">
                @if (status === Status.Error) {
                <p>
                    {{ 'GLOBAL.RATING_FORM.DEFAULT.ERROR' | translate }}
                </p>
                }
            </div>
        </div>
        } @if (status === Status.Success) {
        <div class="row d-flex justify-content-center">
            <div class="col-6">
                <img src="./assets/images/dancing.svg" alt="" class="w-100" />
            </div>
            <div class="col-12 mt-4 text-center">
                <h3>{{ 'GLOBAL.RATING_FORM.SUCCESS.TITLE' | translate }}</h3>
                <p>{{ 'GLOBAL.RATING_FORM.SUCCESS.DESCRIPTION' | translate }}</p>
                <button class="btn btn-black" (click)="closeModal()">
                    {{ 'GLOBAL.RATING_FORM.SUCCESS.CLOSE_BUTTON' | translate }}
                </button>
            </div>
        </div>
        }
    </div>
</div>
