import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
    BooleanField,
    GroupField,
    ImageField,
    KeyTextField,
    LinkField,
    PrismicDocument,
    RichTextField,
    Slice,
    SliceZone,
    TitleField
} from '@prismicio/client';
import { BehaviorSubject, from } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

const GLOBAL_CONTENT = 'global-content';
const GLOBAL_CONTENT_UID = 'global-content';

@Injectable({
    providedIn: 'root'
})
export class GlobalContentService {
    private globalContent = new BehaviorSubject<GlobalContent | null>(null);

    constructor(private translateService: TranslateService) {
        translateService.onLangChange.subscribe((event) => {
            const locale = this.getLocale(event.lang);
            this.fetchGlobalContent(locale).subscribe((globalContent) => {
                this.globalContent.next(globalContent);
            });
        });
    }

    getGlobalContent(): BehaviorSubject<GlobalContent | null> {
        return this.globalContent;
    }

    private fetchGlobalContent(locale: string) {
        return from(
            environment.prismic.client.getByUID<PrismicDocument<GlobalContent>>(GLOBAL_CONTENT, GLOBAL_CONTENT_UID, { lang: locale })
        ).pipe(
            map((result) => {
                return result.data;
            })
        );
    }

    private getLocale(lang: string): string {
        switch (lang) {
            case 'de':
                return 'de-de';
            default:
                return 'en-us';
        }
    }
}

export type GlobalContent = {
    logo_link: LinkField;
    navigation_menu_items: GroupField<GlobalCotentLinkItem>;
    organization: KeyTextField;
    footer_title: TitleField;
    footer_description: RichTextField;
    footer_links: GroupField<GlobalCotentLinkItem>;
    footer_links_legal: GroupField<GlobalCotentLinkItem>;
    signets: GroupField<Signet>;
    address: RichTextField;
    email: KeyTextField;
    linkedin: LinkField;
    xing: LinkField;
    twitter: LinkField;
    facebook: LinkField;
    instagram: LinkField;
    youtube: LinkField;
    body?: SliceZone<any>;
    logos: GroupField<Logo>;
};

export type MenuItemSlice = Slice<
    'menu_item',
    {
        title: TitleField;
        link: LinkField;
        isOpen: BooleanField;
    },
    MenuItem
>;

export type MenuItem = {
    title: KeyTextField;
    link: LinkField;
};

export type GlobalCotentLinkItem = {
    title: KeyTextField;
    link: LinkField;
    anker?: KeyTextField;
};

export type Signet = {
    signet: ImageField;
    link: LinkField;
};

export type Logo = {
    logo: ImageField;
};
