<div class="container pb-5 pb-lg-6">
    <!-- Search field-->
    @if (body.primary.show_search_field) {
    <div class="row mt-5 mt-lg-6">
        <div class="col-12 mb-4">
            <input type="search" [(ngModel)]="searchTerm" [placeholder]="'FAQ_SLICE.SEARCH_INPUT_PLACEHOLDER' | translate" class="w-100" />
        </div>
    </div>
    } @if (body.items | filter : searchTerm : 'question'; as filteredFaqItems) {
    <!-- Pinned Questions -->
    @if (filteredFaqItems | filterPinnedItems : true; as pinnedFaqItem) { @if (pinnedFaqItem.length > 0) {
    <div class="row gx-lg-5 mb-5 mb-6">
        @for (faqItem of pinnedFaqItem; track faqItem) {
        <div class="col-lg-6 d-flex mt-5">
            <div class="shadow p-4 p-lg-5">
                <div class="lottie-wrapper mb-4">
                    @if (isBrowser){
                    <ng-lottie [options]="{ path: '/assets/lottie/pin.json' }"></ng-lottie>
                    }
                </div>
                <h3>{{ faqItem.question }}</h3>
                <div [innerHTML]="faqItem.answer | renderHtml : htmlSerializer"></div>
            </div>
        </div>
        }
    </div>
    } }
    <!-- All Questions-->
    <div class="row">
        @for (faqItem of filteredFaqItems | filterPinnedItems : false; track faqItem) {
        <div class="col-12 mt-4">
            <h3>{{ faqItem.question }}</h3>
            <div [innerHTML]="faqItem.answer | renderHtml : htmlSerializer"></div>
            <hr />
        </div>
        }
    </div>
    <!-- No results -->
    @if (filteredFaqItems.length === 0) {
    <div class="row">
        <div class="col-12 text-center">
            <h3>{{ 'FAQ_SLICE.NO_RESULTS_TITLE' | translate }}</h3>
            <p>{{ 'FAQ_SLICE.NO_RESULTS_DESCRIPTION' | translate }}</p>
        </div>
    </div>
    } @if (body.primary.button_link) {
    <div class="row">
        <div class="col-12">
            <app-link [link]="body.primary.button_link" [cssClass]="'btn btn-black mt-5'">{{ body.primary.button_title }}</app-link>
        </div>
    </div>
    } }
</div>
