import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ApplicationFormService {
    private applicationFormData!: ApplicationFormData;

    updateApplicationFormData(applicationFormData: ApplicationFormData) {
        this.applicationFormData = applicationFormData;
    }

    getApplicationFormData(): ApplicationFormData {
        return this.applicationFormData;
    }

    constructor() {}
}

export interface ApplicationFormData {
    job?: string;
    organization?: string;
}
